import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
// import Main from 'styles/sitemap';
import 'styles/sitemap.scss';

const SiteMap = ({ location }) => (
  <Layout location={location}>
    <Meta
      title="Site Map | ruttl "
      description="Explore the comprehensive ruttl site map for effortless navigation and instant access to every page on our website."
      url="/sitemap/"
    />
    <main className="sitemap-styled-main">
      <section className="sitemap">
        <div className="container">
          <h1>Sitemap</h1>
          <div className="flex flex-no-mob justify-content-between">
            <div>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/404">404</Link>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
                <li>
                  <Link to="/affiliate/">Affiliate</Link>
                </li>
                <li>
                  <Link to="/bugherd-alternative/">Alternative to Bugherd</Link>
                </li>
                <li>
                  <Link to="/marker-io-alternative/">
                    Alternative to Marker-io
                  </Link>
                </li>
                <li>
                  <Link to="/markup-io-alternative/">
                    Alternative to Markup-io
                  </Link>
                </li>
                <li>
                  <Link to="/pastel-alternative/">Alternative to Pastel</Link>
                </li>
                <li>
                  <Link to="/userback-alternative/">
                    Alternative to Userback
                  </Link>
                </li>
                <li>
                  <Link to="/usersnap-alternative/">
                    Alternative to Usersnap
                  </Link>
                </li>
                <li>
                  <Link to="/blog-old/">Blog old</Link>
                </li>
                <li>
                  <Link to="/competitor-template/">Competitor template</Link>
                </li>
                <li>
                  <Link to="/alternatives/">Alternatives</Link>
                </li>
                <li>
                  <Link to="/cookie-policy/">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/customer-stories/">Customer Stories</Link>
                </li>
                <li>
                  <Link to="/features/">Features</Link>
                </li>
                <li>
                  <Link to="/integrations/">Integrations</Link>
                </li>
                <li>
                  <Link to="/knowledge-box/">Knowledge Box</Link>
                </li>
                <li>
                  <Link to="/media-kit/">Media kit</Link>
                </li>
                <li>
                  <Link to="/pricing/">Pricing</Link>
                </li>
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/offers/">Offers</Link>
                </li>
                {/* <li>
                  <Link to="/privilege-plans/">Privilege Plans</Link>
                </li> */}
                <li>
                  <Link to="/product-updates/">Product Updates</Link>
                </li>
                <li>
                  <Link to="/product-videos/">Product Videos</Link>
                </li>
                <li>
                  <Link to="/support/">Support</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions/">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/thanks/">Thanks</Link>
                </li>
                <li>
                  <Link to="/usecases/">Usecases</Link>
                </li>
                <li>
                  <Link to="/website-feedback-tool/">
                    Website Feedback Tool
                  </Link>
                </li>
                <li>
                  <Link to="/blog/write-for-us/">Write for Us</Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="list-head">Features</h4>
              <ul>
                <li>
                  <Link to="/website-commenting-tool/">Comment Mode</Link>
                </li>
                <li>
                  <Link to="/features/edit-website/">Edit Mode</Link>
                </li>
                <li>
                  <Link to="/features/integrations/">Integrations</Link>
                </li>
                <li>
                  <Link to="/features/review-versions-on-websites/">
                    Versioning
                  </Link>
                </li>
                <li>
                  <Link to="/features/static-image-review/">
                    Static Image Review
                  </Link>
                </li>
                <li>
                  <Link to="/features/team-plans/">Team Plans</Link>
                </li>
                <li>
                  <Link to="/features/review-web-applications/">Web Apps</Link>
                </li>
              </ul>
              <h4 className="list-head">Usecases</h4>
              <ul>
                <li>
                  <Link to="/customer-feedback-tool/">Customer Feedback</Link>
                </li>
                <li>
                  <Link to="/usecases/design-review/">Design Review</Link>
                </li>
                <li>
                  <Link to="/usecases/for-designers-and-developers/">
                    For Designers and Developers
                  </Link>
                </li>
                <li>
                  <Link to="/usecases/for-software-companies/">
                    For Software Companies
                  </Link>
                </li>
                <li>
                  <Link to="/online-pdf-annotator-markup-software/">
                    PDF Annotation
                  </Link>
                </li>
                <li>
                  <Link to="/usecases/quality-assurance/">
                    Quality Assurance
                  </Link>
                </li>
                <li>
                  <Link to="/usecases/visual-feedback-tool/">
                    Visual Feedback Tool
                  </Link>
                </li>
                <li>
                  <Link to="/usecases/web-design-agencies/">
                    Web Design Agencies
                  </Link>
                </li>
                <li>
                  <Link to="/website-annotation-tool/">
                    Website Annotation Tool
                  </Link>
                </li>
                <li>
                  <Link to="/website-feedback-tool/">
                    Website Feedback Tool
                  </Link>
                </li>
              </ul>
              <h4 className="list-head">Support</h4>
              <ul>
                <li>
                  <Link to="/support/billing-and-plans/">
                    Billing and Plans
                  </Link>
                </li>
                <li>
                  <Link to="/support/get-started/">Get Started</Link>
                </li>
                <li>
                  <Link to="/support/invite-people/">Invite People</Link>
                </li>
                <li>
                  <Link to="/support/tools-and-tips/">Tools and Tips</Link>
                </li>
                <li>
                  <Link to="/support/using-ruttl/">Using ruttl</Link>
                </li>
              </ul>
            </div>
            <div className="list-block">
              <h4 className="list-head">Customer Stories</h4>
              <ul>
                <li>
                  <Link to="/customer-stories/pixallus/">
                    Pixallus - Web development and Marketing
                  </Link>
                </li>
                <li>
                  <Link to="/customer-stories/afton-negrea/">
                    Afton Negrea - Digital Strategist
                  </Link>
                </li>
                <li style={{ maxWidth: 400 }}>
                  <Link to="/customer-stories/cerana-technologies/">
                    Cerana Technologies - IT service infrastructure / Web
                    development
                  </Link>
                </li>
                <li>
                  <Link to="/customer-stories/agency-5/">
                    Agency5 - Web design business
                  </Link>
                </li>
                <li>
                  <Link to="/customer-stories/agencia-eremo/">
                    Agencia Eremo - Communication agency
                  </Link>
                </li>
                <li>
                  <Link to="/customer-stories/local-marketing-pros/">
                    Local Marketing Pros - Digital marketing agency
                  </Link>
                </li>
              </ul>
              <h4 className="list-head">Blog pages</h4>
              <ul>
                <li>
                  <Link to="/blog/">All Blogs</Link>
                </li>
                <li>
                  <Link to="/product-updates/">Product Updates</Link>
                </li>
                <li>
                  <Link to="/product-videos/">Product Videos</Link>
                </li>
                <li>
                  <Link to="/customer-stories/">Customer Stories</Link>
                </li>
              </ul>
            </div>
          </div>
          <h4 className="list-head">Blogs</h4>
          <ul className="blog-list">
            {/* <li>
              <Link to="/blog/web-app/">web-app</Link>
            </li> */}
            <li>
              <Link to="/blog/how-to-give-design-feedback/">
                How To Give Better Design Feedback
              </Link>
            </li>
            <li>
              <Link to="/blog/web-design-project-planning/">
                5 Ways To Set Up Your Web Design Projects For Success
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-influencers-to-follow-in-2021/">
                3 SaaS Influencers You Need To Follow Right Now
              </Link>
            </li>
            <li>
              <Link to="/blog/customer-feedback-questions/">
                Questions You Must Ask To Collect Better Customer Feedback
              </Link>
            </li>
            <li>
              <Link to="/blog/design-client-mistakes/">
                Common Mistakes That Web Design Clients Make
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-website-design-inspirations/">
                Best Inspirations For SaaS Website Design
              </Link>
            </li>
            <li>
              <Link to="/blog/web-design-mistakes/">
                Major Mistakes To Avoid While Managing Any Web Design Project!
              </Link>
            </li>
            <li>
              <Link to="/blog/how-web-designers-and-developers-collaborate/">
                Designers & Developers: Can They Work Together?
              </Link>
            </li>

            <li>
              <Link to="/blog/design-development-handoff-tools/">
                Best Design Development Handover Tools
              </Link>
            </li>
            <li>
              <Link to="/blog/web-development-podcast/">
                Best Web Development Podcasts In 2023
              </Link>
            </li>
            <li>
              <Link to="/blog/bug-reporting/">
                A Lowdown On Bug Reports And How To Create Good One
              </Link>
            </li>
            <li>
              <Link to="/blog/language-switch-button-design/">
                A Guide To Design Language Switch Button For Your Website
              </Link>
            </li>
            <li>
              <Link to="/blog/books-to-unlock-your-creativity/">
                Books To Unlock Your Creativity
              </Link>
            </li>
            <li>
              <Link to="/blog/concept-testing-and-its-process/">
                What Is Concept Testing And Its Process?
              </Link>
            </li>
            <li>
              <Link to="/blog/work-from-home-tips/">
                5 Work From Home Tips To Boost Your Productivity
              </Link>
            </li>
            <li>
              <Link to="/blog/what-is-user-feedback/">
                What Is User Feedback And How To Collect It?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-overcome-designers-block/">
                How To Overcome Designer&apos;s Block?
              </Link>
            </li>
            <li>
              <Link to="/blog/website-feedback-widget/">
                14 Best Website Feedback Widgets In 2023 For Your Website
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-annotate-pdfs/">
                How to Annotate a PDF: 5 Steps and Best Practices
              </Link>
            </li>
            <li>
              <Link to="/blog/web-design-checklist/">
                Web Design Checklist: 15 Steps to Follow in 2023
              </Link>
            </li>
            <li>
              <Link to="/blog/examples-of-cookie-consent-experiences/">
                5 Interesting Cookie Consent Examples To Get Inspired From!
              </Link>
            </li>
            <li>
              <Link to="/blog/best-website-annotation-tools/">
                10 Best Website Annotation Tools for 2023
              </Link>
            </li>
            <li>
              <Link to="/blog/pastel-icon-sets-to-create-projects/">
                Design Picks: 10 Pastel Icon Sets To Create Iconic Projects
              </Link>
            </li>

            <li>
              <Link to="/blog/user-friendly-website-characteristics/">
                10 Characteristics Of A User Friendly Website
              </Link>
            </li>

            <li>
              <Link to="/blog/types-of-website-feedback-tools/">
                Know Your Users With These 3 Types Of Website Feedback Tools
              </Link>
            </li>
            <li>
              <Link to="/blog/web-design-stats-2022/">
                Web Design Stats To Know In 2022
              </Link>
            </li>
            <li>
              <Link to="/blog/tips-for-web-designers/">
                Tips For Aspiring Web Designers
              </Link>
            </li>
            <li>
              <Link to="/blog/best-wordpress-plugins-in-2022/">
                Explore The 5 Best Wordpress Plugins For 2022
              </Link>
            </li>
            <li>
              <Link to="/blog/importance-of-ssl-certificate/">
                Why You Need An SSL Certificate For Your Website?
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-product-building-tips/">
                Design Decision Tips for SaaS Product Development
              </Link>
            </li>
            <li>
              <Link to="/blog/why-clickup-is-good-for-project-management/">
                Why ClickUp Is Great For Project Management?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-create-terms-and-conditions-page/">
                7 Things To Include In A Terms And Conditions Page
              </Link>
            </li>
            <li>
              <Link to="/blog/visual-collaboration/">
                How Visual Collaboration Helps Remote Teams Work Successfully?
              </Link>
            </li>

            <li>
              <Link to="/blog/best-2022-web-design-blogs/">
                Top 20 Web Design Blogs That You Must Follow In 2023
              </Link>
            </li>
            <li>
              <Link to="/blog/ux-storytelling/">
                3 Tips to Improve Your UX Using Storytelling
              </Link>
            </li>
            <li>
              <Link to="/blog/white-label-tools-for-design-agency/">
                Benefits of White Labelled Tools for Design Agencies
              </Link>
            </li>
            <li>
              <Link to="/blog/good-visual-feedback/">
                What&apos;s the Difference Between Critiquing & Criticizing?
              </Link>
            </li>

            <li>
              <Link to="/blog/reduce-website-design-turnaround-time/">
                Tips to reduce turnaround time on web design projects
              </Link>
            </li>
            <li>
              <Link to="/blog/project-brief-questions-to-ask-clients-before-starting-web-design-project/">
                Improve Your Web Project Brief With These Questions
              </Link>
            </li>
            <li>
              <Link to="/blog/importance-of-content-in-web-design/">
                How Content Plays An Important Part In Web Design Process?
              </Link>
            </li>

            <li>
              <Link to="/blog/ruttl-2021-year-in-review/">
                ruttl&apos;s 2021 Milestones: Year End Review
              </Link>
            </li>

            <li>
              <Link to="/blog/podcasts-for-ux-designers/">
                5 Best Must-Listen Podcasts for Every UX Designer
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-product-design/">
                What to Consider before Starting Saas Product Design?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-design-faq-page/">
                Effective Rules for Designing a Good FAQ Page
              </Link>
            </li>
            <li>
              <Link to="/blog/free-design-resources/">
                Say Bye To Creative Block With These Free Design Resources
              </Link>
            </li>

            <li>
              <Link to="/blog/how-to-reduce-the-time-required-to-collect-design-feedback/">
                Time saving tips to efficiently collect design feedback
              </Link>
            </li>

            <li>
              <Link to="/blog/why-ruttl-was-built/">
                How ruttl Was Born Out Of Delays in Development?
              </Link>
            </li>
            <li>
              <Link to="/blog/what-is-ruttl/">
                What If…ruttl Solved All Your Website Design Problems?
              </Link>
            </li>
            <li>
              <Link to="/blog/7-ways-to-give-feedback-on-designs/">
                7 Ways to Give Better Design Feedback
              </Link>
            </li>
            <li>
              <Link to="/blog/responsive-websites/">
                Why Your Website Needs to Be Responsive
              </Link>
            </li>

            <li>
              <Link to="/blog/website-content-writing/">
                Here&apos;s How We&apos;re Improving Content Writing for
                Websites
              </Link>
            </li>
            <li>
              <Link to="/blog/reviewing-developed-websites/">
                3 Key Aspects of Post-Development Website Reviews
              </Link>
            </li>
            <li>
              <Link to="/blog/remote-working/">
                Is Remote Working Really Challenging?
              </Link>
            </li>
            <li>
              <Link to="/blog/best-website-builders-2021/">
                Create A Free Website With These Website Builders!
              </Link>
            </li>
            <li>
              <Link to="/blog/website-navigation-examples/">
                5 Amazing and Iconic Website Navigation Examples
              </Link>
            </li>
            <li>
              <Link to="/blog/adaptive-vs-responsive-design/">
                What is the difference between Adaptive and Responsive Design?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-avoid-team-chaos-on-web-design-project/">
                Smooth web design projects: tips to avoid team chaos
              </Link>
            </li>

            <li>
              <Link to="/blog/user-centric-design/">
                User-Centric Design Basics for Flawless Web Design
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-get-freelance-web-design-jobs/">
                Best Ways To Find Freelance Web Design Jobs Online In 2021
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-grow-bootstrapped-saas-startup/">
                Building a Bootstrapped SaaS Startup
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-user-acquisition/">
                How ruttl Went From 500 To 1000 Users In 1 Month!
              </Link>
            </li>

            <li>
              <Link to="/blog/video-commenting/">
                Introducing The Video Commenting Feature In Ruttl
              </Link>
            </li>
            <li>
              <Link to="/blog/website-review-process/">
                Why I Started Using Slides for Illustration Projects
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-deal-with-negative-feedback-from-clients/">
                How To Graciously Accept Negative Feedback
              </Link>
            </li>
            <li>
              <Link to="/blog/the-importance-of-design-thinking/">
                The Importance of Design Thinking
              </Link>
            </li>
            <li>
              <Link to="/blog/material-design/">
                Considering Material Design? Here&apos;s What You Should Know
              </Link>
            </li>
            <li>
              <Link to="/blog/customer-experience-strategy/">
                5 Ways to Create A Great Customer Experience Strategy
              </Link>
            </li>
            <li>
              <Link to="/blog/user-acceptance-testing/">
                How Can You Make User Acceptance Testing Work for You?
              </Link>
            </li>
            <li>
              <Link to="/blog/best-product-management-tools/">
                Best Tools for Product Management
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-build-ui-ux-portfolio/">
                How to Build a Powerful UI UX Portfolio
              </Link>
            </li>
            <li>
              <Link to="/blog/visual-feedback-in-web-development/">
                The Role of Visual Feedback in Web Development
              </Link>
            </li>
            <li>
              <Link to="/blog/best-web-design-courses/">
                A Curated List Of The Best Web Design Courses for Beginners
              </Link>
            </li>
            <li>
              <Link to="/blog/heat-maps/">
                How Heat Maps Improve Website Design and UX
              </Link>
            </li>

            <li>
              <Link to="/blog/website-wireframes/">
                Everything You Need To Know About Wireframes
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-choose-website-colors/">
                Why Choosing The Right Colours For Your Website Is Important
              </Link>
            </li>
            <li>
              <Link to="/blog/best-tools-for-freelance-designers/">
                5 Amazing Tools for Freelance Designers
              </Link>
            </li>
            <li>
              <Link to="/blog/website-feedback-tool/">
                Why Should Design Agencies Use A Website Feedback Tool?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-review-website/">
                How To Properly Review A Website?
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-boost-website-engagement/">
                5 Tips To Boost Website Audience Engagement
              </Link>
            </li>
            <li>
              <Link to="/blog/intuitive-design/">
                Learn How To Craft An Intuitive Design That Users Love
              </Link>
            </li>
            <li>
              <Link to="/blog/ux-audit/">
                UX Auditing And Doing It The Right Way
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-create-good-landing-page/">
                6 Elements Needed To Create A High Converting Landing Page
              </Link>
            </li>
            <li>
              <Link to="/blog/designers-developers-collaboration/">
                6 Hidden Secrets Of Better Design-Development Collaboration
              </Link>
            </li>
            <li>
              <Link to="/blog/why-design-teams-need-ruttl/">
                Why ruttl Is The Future Of Collaboration Between Design Teams
              </Link>
            </li>
            <li>
              <Link to="/blog/best-web-design-softwares/">
                Handpicked and Tested: The Best Tools For Web Design Teams
              </Link>
            </li>
            <li>
              <Link to="/blog/web-development-stages/">
                Clutter To Clarity: A Guide On Important Stages Of Web Design
              </Link>
            </li>
            <li>
              <Link to="/blog/website-annotation-tool/">
                The Right Way To Select A Website Annotation Tool
              </Link>
            </li>
            <li>
              <Link to="/blog/10-tips-for-freelance-web-designers/">
                10 Tips To Succeed As A Freelance Web Designer!
              </Link>
            </li>
            <li>
              <Link to="/blog/best-tools-for-remote-creative-teams/">
                8 Tools That Remote Creative Teams Never Thought They Needed!
              </Link>
            </li>
            <li>
              <Link to="/blog/building-and-improving-client-relationships/">
                Build Strong Relationships With Web Design Clients
              </Link>
            </li>
            <li>
              <Link to="/blog/best-web-design-black-friday-deals-2021/">
                best-web-design-black-friday-deals-2021
              </Link>
            </li>
            <li>
              <Link to="/blog/rebranding-vs-redesign/">
                Should You Rebrand Yourself Or Redesign Your Website?
              </Link>
            </li>
            <li>
              <Link to="/blog/freelance-design-projects/">
                Valuable Tips To Help You Win More Freelance Design Projects
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-increase-website-speed/">
                5 Quick Fixes To Speed Up Your Website
              </Link>
            </li>
            <li>
              <Link to="/blog/hiring-tips/">
                Important Hiring Tips For SaaS Founders
              </Link>
            </li>
            <li>
              <Link to="/blog/reduce-bounce-rate/">
                How To Retain Website Visitors From Dropping Off
              </Link>
            </li>
            <li>
              <Link to="/blog/paralysis-by-analysis/">
                Avoid Paralysis By Analysis With This Powerful Mindset Tip!
              </Link>
            </li>
            <li>
              <Link to="/blog/email-marketing-tips/">
                6 Most Effective Email Marketing Tips That You Need To Know!
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-improve-product/">
                The Uncommon Secret For Creating A Better Product!
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-improve-saas-product/">
                Tip Of The Week To Improve Your SaaS Product!
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-social-media-marketing/">
                Should SaaS Founders Use Social Media Marketing
              </Link>
            </li>
            <li>
              <Link to="/blog/content-marketing/">
                5 Questions To Ask Before Doing Content Marketing!
              </Link>
            </li>
            <li>
              <Link to="/blog/ruttl-slack-community/">
                Loving Ruttl? We&ap&apos;ve Created A New Slack Community!
              </Link>
            </li>

            <li>
              <Link to="/blog/user-acquisition-methods/">
                5 User Acquisition Methods That Work For Us
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-growth/">
                3 Most Important Terms You Need To Know About SaaS Growth!
              </Link>
            </li>
            <li>
              <Link to="/blog/seo-trick/">
                How To Use Footer Design For Better SEO
              </Link>
            </li>
            <li>
              <Link to="/blog/onboarding-new-users/">
                On-Boarding Things That We Do For New Users
              </Link>
            </li>
            <li>
              <Link to="/blog/angry-users/">
                Dealing With Angry Users? Here&apos;s How ruttl Dealt With It!
              </Link>
            </li>
            <li>
              <Link to="/blog/seo-growth-hack/">
                The Latest SEO Growth Hack That Worked For Us
              </Link>
            </li>
            <li>
              <Link to="/blog/product-launch-tips/">
                Things You Should Focus On During Product Launch Day
              </Link>
            </li>
            <li>
              <Link to="/blog/saas-product-pricing/">
                Learn How To Price Your SaaS Product
              </Link>
            </li>
            <li>
              <Link to="/blog/7-ui-ux-communities/">
                7 UI/UX Communities You Need To Join In 2021!
              </Link>
            </li>
            <li>
              <Link to="/blog/ui-ux-concepts/">
                Important UI/UX Concepts I Learnt In My Career!
              </Link>
            </li>
            <li>
              <Link to="/blog/how-to-work-with-multiple-clients/">
                Have Multiple Design Clients? Then Read These Tips!
              </Link>
            </li>
            <li>
              <Link to="/blog/client-feedback-on-web-designs/">
                Why Client Feedback Is Crucial For Web Designing?
              </Link>
            </li>
            <li>
              <Link to="/blog/design-systems/">
                The Critical Role Of Design Systems In Building Products!
              </Link>
            </li>
            <li>
              <Link to="/blog/user-feedback-strategy/">
                Top Practices For User Feedback Strategy
              </Link>
            </li>
            <li>
              <Link to="/blog/web-design-vs-web-development/">
                How Web Design And Development Are Different?
              </Link>
            </li>
            <li>
              <Link to="/blog/design-system-roadmap/">
                Steps To Build Design System Roadmap
              </Link>
            </li>
            <li>
              <Link to="/blog/design-team-collaboration/">
                How To Boost Design Team Collaboration?
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </main>
  </Layout>
);

SiteMap.propTypes = {
  location: PropTypes.object,
};

export default SiteMap;
